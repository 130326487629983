import React, {Component} from 'react';
import '../../styles/css/SlidingPuzzle.css';
import { confirmAlert } from '../utils/react-confirm-alert';

class SlidingPuzzle extends Component {
    constructor(props) {
        super(props);
        const baseDistance = 34.5
        this.state = {
            baseDistance: baseDistance,
            tileMap: {
                1: {
                    tileNumber: 1,
                    position: 1,
                    top: 0,
                    left: 0
                },
                2: {
                    tileNumber: 2,
                    position: 2,
                    top: 0,
                    left: baseDistance
                },
                3: {
                    tileNumber: 3,
                    position: 3,
                    top: 0,
                    left: baseDistance * 2
                },
                4: {
                    tileNumber: 4,
                    position: 4,
                    top: baseDistance,
                    left: 0
                },
                5: {
                    tileNumber: 5,
                    position: 5,
                    top: baseDistance,
                    left: baseDistance
                },
                6: {
                    tileNumber: 6,
                    position: 6,
                    top: baseDistance,
                    left: baseDistance * 2
                },
                7: {
                    tileNumber: 7,
                    position: 7,
                    top: baseDistance * 2,
                    left: 0
                },
                8: {
                    tileNumber: 8,
                    position: 8,
                    top: baseDistance * 2,
                    left: baseDistance
                },
                empty: {
                    position: 9,
                    top: baseDistance * 2,
                    left: baseDistance * 2
                }
            },
            lastShuffled: null,
            shuffleTimeouts: [],
            numberOfMoves: 0
        }
        this.shuffleLoop=this.shuffleLoop.bind(this);
        this.setup=this.setup.bind(this);
        this.tileClicked=this.tileClicked.bind(this);
    }

    setup(tile) {
            const tileId = tile.id;
            const tileMap = this.state.tileMap;
            const parentX = document.querySelector(".sliding-puzzle").clientHeight;
            const xMovement = parentX * (tileMap[tileId].left/100);
            const yMovement = parentX * (tileMap[tileId].top/100);
            tile.style.webkitTransform = "translateX(" + xMovement + "px) " + "translateY(" + yMovement + "px)";
            this.recolorTile(tile, tileId);
        }

    tileClicked(event) {
            const successfulMove = this.moveTile(event.target);
            if(successfulMove !== false){
                let numberOfMoves = this.state.numberOfMoves;
                this.setState({
                    numberOfMoves: numberOfMoves+=1
                })
            }

            if (this.checkSolution()) {
                this.props.winningMessage(this.state.numberOfMoves);
            }
        }

    // Returns true/false based on if the puzzle has been solved
    checkSolution() {
        const tileMap = this.state.tileMap;
        if (tileMap.empty.position !== 9) return false;

        for (const key in tileMap) {
            if ((key != 1) && (key !== "empty")) {
                if (tileMap[key].position < tileMap[key-1].position) return false;
            }
        }
        return true;
    }

    // Shuffles the current tiles
    async shuffle() {
        let shuffleDelay = 0;
        this.shuffleLoop();

        let shuffleCounter = 0;
        while (shuffleCounter < 20) {
            shuffleDelay += 1;
            const tempShuffleArray = this.state.shuffleTimeouts;
            tempShuffleArray.push(setTimeout(this.shuffleLoop, shuffleDelay));
            await this.setState({
                shuffleTimeouts: tempShuffleArray
            })
            shuffleCounter++;
        }
    }

    shuffleLoop() {
        const tileMap = this.state.tileMap;
        let lastShuffled = this.state.lastShuffled;
        const emptyPosition = tileMap.empty.position;
        const shuffleTiles = this.movementMap(emptyPosition);
        const tilePosition = shuffleTiles[Math.floor(Math.floor(Math.random()*shuffleTiles.length))];
        let locatedTile;
        let locatedTileNumber;
        for(let i = 1; i <= 8; i++) {
            if (tileMap[i].position === tilePosition) {
                locatedTileNumber = tileMap[i].tileNumber;
                locatedTile = document.querySelectorAll('.tile')[locatedTileNumber-1];
            }
        }
        if (lastShuffled !== locatedTileNumber) {
            this.moveTile(locatedTile);
            this.setState({lastShuffled: locatedTileNumber});
        } else {
            this.shuffleLoop();
        }

    }

    solvePuzzle(){
        const tiles = document.querySelectorAll('.tile');
        const baseDistance = 34.5
        const correctPosition = [
            {
                tileNumber: 1,
                    position: 1,
                    top: 0,
                    left: 0
            },
            {
                tileNumber: 2,
                    position: 2,
                    top: 0,
                    left: baseDistance
            },
            {
                tileNumber: 3,
                    position: 3,
                    top: 0,
                    left: baseDistance * 2
            },
            {
                tileNumber: 4,
                    position: 4,
                    top: baseDistance,
                    left: 0
            },
            {
                tileNumber: 5,
                    position: 5,
                    top: baseDistance,
                    left: baseDistance
            },
            {
                tileNumber: 6,
                    position: 6,
                    top: baseDistance,
                    left: baseDistance * 2
            },
            {
                tileNumber: 7,
                    position: 7,
                    top: baseDistance * 2,
                    left: 0
            },
            {
                tileNumber: 8,
                    position: 8,
                    top: baseDistance * 2,
                    left: baseDistance
            },
            {
                position: 9,
                    top: baseDistance * 2,
                    left: baseDistance * 2
            }
        ];
        const tileMap = this.state.tileMap;
        for(let i = 0; i < tiles.length; i++) {
            const tileNumber = tiles[i].id;
            const parentX = document.querySelector(".sliding-puzzle").clientHeight;
            const emptyTop = correctPosition[i].top;
            const emptyLeft = correctPosition[i].left;
            const emptyPosition = correctPosition[i].position;
            const xMovement = parentX * (emptyLeft/100);
            const yMovement = parentX * (emptyTop/100);
            tiles[i].style.webkitTransform = "translateX(" + xMovement + "px) " + "translateY(" + yMovement + "px)";
            tileMap[tileNumber].top = emptyTop;
            tileMap[tileNumber].left = emptyLeft;
            tileMap[tileNumber].position = emptyPosition;
            this.recolorTile(tiles[i], tileNumber);
        }
        tileMap.empty.top = correctPosition[8].top;
        tileMap.empty.left = correctPosition[8].left;
        tileMap.empty.position = correctPosition[8].position;
        this.moveTile(tiles[7])
    }

    // Check if tile is in correct place!
    recolorTile(tile, tileId) {
        if (tileId == this.state.tileMap[tileId].position) {
            tile.classList.remove("error");
        } else {
            tile.classList.add("error");
        }
    }

    // Moves tile to empty spot
    // Returns error message if tile cannot be moved
    moveTile(tile) {
        // Check if Tile can be moved
        // (must be touching empty tile)
        // (must be directly perpendicular to empty tile)
        const tileNumber = tile.id;
        const tileMap = this.state.tileMap;
        const parentX = document.querySelector(".sliding-puzzle").clientHeight;
        if (!this.tileMovable(tileNumber)) {
            console.log("Tile " + tileNumber + " can't be moved.");
            return false;
        }

        // Swap tile with empty tile
        const emptyTop = tileMap.empty.top;
        const emptyLeft = tileMap.empty.left;
        const emptyPosition = tileMap.empty.position;
        tileMap.empty.top = tileMap[tileNumber].top;
        tileMap.empty.left = tileMap[tileNumber].left;
        tileMap.empty.position = tileMap[tileNumber].position;

        // tile.style.top = emptyTop  + '%';
        // tile.style.left = emptyLeft  + '%';

        const xMovement = parentX * (emptyLeft/100);
        const yMovement = parentX * (emptyTop/100);
        tile.style.webkitTransform = "translateX(" + xMovement + "px) " + "translateY(" + yMovement + "px)";

        tileMap[tileNumber].top = emptyTop;
        tileMap[tileNumber].left = emptyLeft;
        tileMap[tileNumber].position = emptyPosition;

        this.recolorTile(tile, tileNumber);
    }

    // Movement map
    movementMap(position) {
        if (position === 9) return [6, 8];
        if (position === 8) return [5, 7, 9];
        if (position === 7) return [4, 8];
        if (position === 6) return [3, 5, 9];
        if (position === 5) return [2, 4, 6, 8];
        if (position === 4) return [1, 5, 7];
        if (position === 3) return [2, 6];
        if (position === 2) return [1, 3, 5];
        if (position === 1) return [2, 4];
    }

    // Determines whether a given tile can be moved
    tileMovable(tileNumber) {
        const tileMap = this.state.tileMap;
        const selectedTile = tileMap[tileNumber];
        const emptyTile = tileMap.empty;
        const movableTiles = this.movementMap(emptyTile.position);

        return movableTiles.includes(selectedTile.position);
    }

    componentDidMount() {
        // Board setup according to the tileMap
        const tiles = document.querySelectorAll('.tile');
        let delay = -50;
        for(let i = 0; i < tiles.length; i++) {
            tiles[i].addEventListener('click', this.tileClicked ,true );
            // delay += 50;
            setTimeout(this.setup, delay, tiles[i]);
        }
        this.shuffle();
    }

    restartClicked(){
        confirmAlert({
            variables: this.props.variables,
            title: "Are You Sure?",
            message: "Restarting will delete all your progress",
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.shuffle();
                        this.setState({
                            numberOfMoves:0
                        })
                    }
                },
                {
                    label: 'No'
                }
            ]
        })
    }

    render() {
        let tilesArray = this.props.items || [];
        const tenantVariables = this.props.variables || {};
        const developerMode = this.props.developerMode || false;
        const secondaryColor = tenantVariables.secondaryColor || "white";
        const primaryColor = tenantVariables.primaryColor || "black";
        const movesTaken = this.state.numberOfMoves || 0;
        const backgroundColor = tenantVariables.puzzleBackgroundColor || "#2C3E50";
        const logOutButtonColor = tenantVariables.logOutButtonColor || "white";
        return (
            <div>
                <figure className="sliding-puzzle-figure">
                  <ul className="sliding-puzzle" style={{backgroundColor: backgroundColor, borderColor: backgroundColor}}>
                    <li className="tile" id={1} style={{background:`url(${tilesArray[0].dataURI})`, backgroundSize:"cover"}}/>
                    <li className="tile" id={2} style={{background:`url(${tilesArray[1].dataURI})`, backgroundSize:"cover"}}/>
                    <li className="tile" id={3} style={{background:`url(${tilesArray[2].dataURI})`, backgroundSize:"cover"}}/>
                    <li className="tile" id={4} style={{background:`url(${tilesArray[3].dataURI})`, backgroundSize:"cover"}}/>
                    <li className="tile" id={5} style={{background:`url(${tilesArray[4].dataURI})`, backgroundSize:"cover"}}/>
                    <li className="tile" id={6} style={{background:`url(${tilesArray[5].dataURI})`, backgroundSize:"cover"}}/>
                    <li className="tile" id={7} style={{background:`url(${tilesArray[6].dataURI})`, backgroundSize:"cover"}}/>
                    <li className="tile" id={8} style={{background:`url(${tilesArray[7].dataURI})`, backgroundSize:"cover"}}/>
                  </ul>
                </figure>
                <div style={{marginTop:10}}>
                    <p style={{color: logOutButtonColor || "white"}}><span style={{fontSize:30}}>{movesTaken}</span></p>
                </div>
                {developerMode &&
                    <div style={{marginTop: 5}}>
                        <button className="btn btn-next" onClick={() => this.solvePuzzle()}
                                style={{backgroundColor: primaryColor || "black", color: secondaryColor || "white"}}>Solve Puzzle
                        </button>
                    </div>
                }
                <div style={{marginTop:5}}>
                    <button className="btn btn-next" onClick={()=> this.restartClicked()} style={{backgroundColor: primaryColor || "black", color: secondaryColor || "white"}}>RESTART</button>
                </div>
                {/*<div style={{marginTop:10, float:"left"}}>*/}
                {/*    <button onClick={()=>this.props.showRealImage()} className="btn btn-logout" style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>Show Image</button>*/}
                {/*</div>*/}
            </div>
        )
  }
}

export default SlidingPuzzle;
